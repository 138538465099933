import React from 'react';

// libraries
import { css } from '@emotion/core';

import Link from 'components/Link';
import Head from 'components/Head';
import MainLayout from 'components/MainLayout';

import background from 'images/404.svg';
import discord from 'images/discord.svg';

export default function Discord() {
  return (
    <MainLayout>
      <Head title="Discord" />
      <section css={hero}>
        <h2 css={title}>Discord Integration Complete!</h2>
        <p css={blurb}>
          Excellent, you're now all set up to receive notifications in Discord
          and other goodies we still have in the pipeline. Feel free to close
          this window.
        </p>
        <p css={blurb}>
          You will only receive notifications as long as you're a member of the
          Parsec Discord server. The bot automatically added you just now, but
          in case that didn't work or you left the server and want to rejoin,
          you can do so{' '}
          <Link target="_blank" href="https://discord.gg/3TS2emF">
            here
          </Link>
          .
        </p>
        <img
          src={discord}
          css={css`
            max-width: 30rem;
          `}
          alt=""
        />
      </section>
    </MainLayout>
  );
}

const hero = css`
  flex: 1;
  background: center url(${background}) no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 60rem;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const title = css`
  margin-bottom: 1.2rem;
`;

const blurb = css`
  max-width: 60rem;
`;
